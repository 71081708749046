import '../styles/globals.css';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { Noto_Sans, Sarala } from 'next/font/google';
import Head from 'next/head';
import Script from 'next/script';
import { ReactElement, ReactNode } from 'react';

import type { AppProps } from 'next/app';

const PreviewProvider = dynamic(
  () => import('components/PreviewProvider/PreviewProvider')
);

const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

const noto = Noto_Sans({
  subsets: ['latin'],
  weight: ['300', '400', '700'],
  fallback: ['Helvetica', 'sans-serif'],
});

const sarala = Sarala({
  subsets: ['latin'],
  weight: ['400', '700'],
  fallback: ['Helvetica', 'sans-serif'],
});

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);
  const { draftMode, token } = pageProps;

  const title = 'Leading Tasmanian Criminal Law Firm';
  const description = 'Tasmania based law firm specialising in criminal law';

  return (
    <>
      <style jsx global>{`
        :root {
          --noto-font: ${noto.style.fontFamily};
          --sarala-font: ${sarala.style.fontFamily};
        }
      `}</style>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title key="title">Monk Lawyers | {title}</title>
        <meta
          name="title"
          content={`Monk Lawyers | ${title}`}
          key="metatitle"
        />
        <meta name="description" content={description} key="metadescription" />

        <meta property="og:title" content={title} key="ogtitle" />
        <meta
          property="og:url"
          content="https://www.monklawyers.com.au"
          key="ogurl"
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_SERVER_URL}/images/Footer logo-12.png`}
          key="ogimage"
        />
        <meta property="og:type" content="website" key="ogtype" />
        <meta
          property="og:description"
          content={description}
          key="ogdescription"
        />

        <link rel="icon" href="/images/Tab logo-05.png" />
      </Head>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
        `}
      </Script>
      {getLayout(
        draftMode ? (
          <PreviewProvider token={token}>
            <Component {...pageProps} />
          </PreviewProvider>
        ) : (
          <Component {...pageProps} />
        )
      )}
    </>
  );
}
